.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: 'poppins-regular';
  src: local('poppins-regular'), url('./common/media/fonts/Poppins-Regular.ttf');
}

* {
  font-family: poppins-regular;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: poppins-regular;
}

.form-ph-input > div > input {
  font-family: poppins-regular !important;
  background-color: #ebf5f4 !important;
  border: none !important;
  color: #007747 !important;
}

.form-ph-input > div > input::placeholder {
  color: #9a9a9ade !important;
}

.form-ph-dropdown,
.form-ph-dropdown > div > .item {
  font-family: poppins-regular !important;
  background-color: #ebf5f4 !important;
  border: none !important;
  color: #007747 !important;
}

.form-ph-dropdown > .default {
  color: #9a9a9ade !important;
}

.form-ph-input > .error > input::placeholder {
  color: #e0b4b4 !important;
}

.form-ph-input > .error > input {
  background-color: #fff6f6 !important;
  border: 1px solid #e0b4b4 !important;
  color: #9f3a38 !important;
}

.error.form-ph-dropdown > .default {
  color: #9f3a38 !important;
}

.error.form-ph-dropdown,
.error.form-ph-dropdown > div > .item {
  background-color: #fff6f6 !important;
  border: 1px solid #e0b4b4 !important;
  color: #9f3a38 !important;
}

.pop-up-error {
  background-color: #fff6f6 !important;
  color: #9f3a38 !important;
}

.pop-up-error::before {
  background-color: #fff6f6 !important;
}
