.historyWrapper {
  max-height: 250px;
  overflow: auto;
}

.statisticsWrapper {
  margin-bottom: 2em !important;
  text-align: center;
}

.stats {
  margin-bottom: 2em;
}
